@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.big-promise {
	padding: $HomePage-big-promise-padding;
	background-color: $HomePage-big-promise-background-color;
	color: $HomePage-big-promise-color;
	text-shadow: $HomePage-big-promise-text-shadow;
	min-height: $HomePage-big-promise-min-height;
	width: 100%;
	text-align: center;
}
#welcome-to-the-future {
	margin-top: -45rem;
	h1, h2 {
		display: flex;
	}
	h1 {
		font-size: 68px;
		font-family: $font-family-2;
	}
	h2 {
		font-size: 28px;
	}
}
#link-card-buttons {
	margin-top: 2rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}
#HomepageIncentives {
	background: linear-gradient(to right, $BG_grad_start, $BG_grad_end);
	color: #ffffff;
	text-align: center;
	h2 {
		font-size: 2rem;
		padding-top: 2rem;
		padding-bottom: 1rem;
	}
	.lead {
		width: 40%;
		margin: 0 auto;
	}
	.lead, h2 {
		font-family: $font-family-2;
	}
	.btn {
		background: none;
		background-color: white;
		color: $darkBlue;
		border-radius: 2px;
		margin-bottom: 2rem !important;
		font-family: $font-family-2;
	}
	.homepage-incentive-catalog {
		max-width: 80%;
		margin: 0 auto;
		overflow: hidden;
	}
}

@media(max-width:777px) {
	#HomepageIncentives {
		.lead {
			width: 80%;
		}
	}
}