@import "src/client_customizations/scss/client_master.scss";

.IncentivePreferences {
	.h5 {
		.badge {
			font-size: 50%;
			vertical-align: middle;
		}
	}
	.tooltip-bubble {
		min-width: 250px;
		max-width: 250px;
	}
	.input-well {
		background-color: $IncentivePreferences-input-well-background-color;
		padding: 0;
		border-radius: 0;
		border: none;
		.btn {
			width: 100%;
			background: none;
			color: #0072ce;
		}
		.btn.active {
			color: white;
			background: linear-gradient(to right, $button_grad_start, $button_grad_end); 
		}
	}
}
