@import "../../client_customizations/scss/client_master.scss";

.incentives-page {
    background-color: $offWhite !important;
    padding-bottom: 0 !important;
    h1 {
        font-family: $font_family_2;
    }
    .btn {
        padding: 0.5rem 4rem; 
        border-radius: 4px;
    }
    .IncentiveCard {
        box-shadow: 0 20px 41px rgba(0, 0, 0, 0.1);
        .incentive-card-body {
            max-height: 14rem;
            .card-title {
                font-size: 1.5rem;
                height: 2.5rem;
            }
    
        }
    }
}