@import "src/client_customizations/scss/client_master.scss";

.ev-filter-controls {
       border:none;
       box-shadow: 0 3px 7px -2px rgba(2, 63, 32, 0.3);
       margin-bottom: 1rem;

       p.h2 {
              color:#2B2B2B;
              font-weight: 400;
              margin-bottom: 30px;
       }

       .filter-switch {
              margin:10px 0;

              span.badge {
                  img {
                      width:25px;
                  }
              }

              span.name {
                  font-size: 0.75rem;
              }
  
              .switch {
                  float:right;
              }
  
              .clearfix {
                  clear:both;
              }
       }

       .form-group {
              span.label-style {
                     font-weight: 400;
                     color:#2B2B2B;
                     margin:0 0 5px;
              }

              .btn-grid-container {
                     margin-top:15px;

                     .btn {
                            background: none;
                            background-color:#fff;
                            color:#0072CE;
                     }
                     .btn.active {
                            background: linear-gradient(to right, $button_grad_start, $button_grad_end); 
                            color: white;
                     }
              }
       }

       .legal-disclaimer {
              text-align: left;
       }
}