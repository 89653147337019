@import "src/client_customizations/scss/client_master.scss";
.Footer {
	color: $GenericFooter-color;
	background: linear-gradient(to right, $BG_grad_start, $BG_grad_end);
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: underline;
		}
	}
	a.footerLink {
		display: block;
	}
	.social-icons {
		margin-top: -0.5em;
		a {
			margin-left: 1rem;
		}
	}
	.p {
		color: $GenericFooter-links-color;
	}
	.footer-icon {
		margin-left: -1rem;
	} 
	.footer-copyright {
		margin-top: 1.5rem;
		font-size: $GenericFooter-links-font-size;
		font-weight: 300;
	}
	.footer-zappycopyright {
		margin-top: 0.5rem;
		font-size: $GenericFooter-links-font-size;
		font-weight: 300;
	}
	.footerLinks {
		margin-top: -0.25rem;
	}
	.footerLinks-col-1 {
		// @TODO will need to make this depend on size
		padding-left: 3.75rem !important;
	}
	#translate {
		text-align: right;
		margin-top: 0.25rem;
		padding-right: 0;
		select {
			font-size: $GenericFooter-links-font-size;
			padding: 0.25rem;
		}
	}
	.legal-disclaimer {
		padding-top: 1rem;
		font-size: $disclaimer-style-font-size;
		font-weight: $disclaimer-style-font-weight;
		color: $disclaimer-style-color;
	}
}

@media(max-width: 575px) {
	.footer-row {
		padding: 0 5px;
	}
	#social {
		padding: 1rem 2rem;
	}
	.social-icons {
		div {
			display: inline;
		}
	}
}

.disclaimer-container {
       div {
              text-align: right;
              padding:0 !important;
              background-color:transparent !important;

              img {
                     max-width: 100%;
                     margin:0 auto 20px;
              }

              p {
                     text-align: left;
                     color:white;
                     font-size:12px;
                     line-height: 1.5em;
              }
       }
}