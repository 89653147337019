@import "src/client_customizations/scss/client_master.scss";

.EVsSort {
	margin-bottom: 15px;
	label {
		font-weight: $EVsSort-label-font-weight;
		font-size: $EVsSort-label-font-size;
		color: $EVsSort-label-color;
		letter-spacing:$EVsSort-label-letter-spacing;
		line-height: $EVsSort-label-line-height;
	}
	select {
		background: $EVsSort-select-background;
		border: $EVsSort-select-border;
		border-radius:$EVsSort-select-border-radius;
		font-size: $EVsSort-select-font-size;
		color: $EVsSort-select-color;
		letter-spacing: $EVsSort-select-letter-spacing;
	}
}
#EVs {
	background-color: $offWhite;
}
@media (min-width: 576px) {
	.EVsSort {
		float: right;
	}
}