@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 400px;
	margin: 0 auto;
}
.link-card {
	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 80px;
	cursor: pointer;
	position: relative;
	.titleFrame {
		p {
			position: relative;
			left: -50%;
		}
		position: absolute;
		bottom: 1rem;
		left: 50%;
		width: 100%
	}
	&:hover {
		p {
			text-decoration: none;
		}
	}
	.evc-card {
		background: none;
		border: 1px solid #fff;
		padding: 0.5rem;
		border-radius: 0px;
		color: #fff;
		p {
			line-height: 1.25rem;
			font-weight: bold;
		}
		.icon-frame-hover {
			display: none;
		} 
		.icon-frame-no-hover {
			display: inline;
		}
		&.opaque {
			background-color: rgba(0, 0, 0, 0.6);
		}
		&:hover {
			background-color: #fff;
			color: #333333;
			.icon-frame-hover {
				display: inline;
			} 
			.icon-frame-no-hover {
				display: none;
			}	
		}
	}
}
@media (max-width: 575px) {
	.link-card-container {
		max-width: 90%;
		.link-card {
			margin: 0 0.1rem;
			.evc-card {
				height: 120px;
				p {
					font-size: 0.65rem;
				}
				img {
					width: 30px;
				}
				.titleFrame {
					bottom: 0.1rem;
				}
			}
		}
	}
}
@media (min-width: 576px) {
	.link-card-container {
		max-width: 80%;
	}
	.link-card {
		flex-basis: 120px;
		img {
			width: 50px;
			margin-bottom: 2rem;
		}
		.icon-frame {
			min-height: 50px;
		}
		.evc-card {
			p {
				font-size: 0.85rem;
			}
			.titleFrame {
				bottom: 0.1rem;
			}
			padding: 2rem;
		}
	}
}
@media (min-width: 768px) {
	.link-card-container {
		min-width: 75%;
	}
	.link-card {
		flex-basis: 245px;
		height: 245px;
		margin: 0 1rem;
		.icon-frame {
			min-height: 120px;
			img {
				width: auto;
			}
		}
		.evc-card {
			padding: 3rem;
			p {
				font-size: 1rem;
			}
		}
	}
}
