@import "src/client_customizations/scss/client_master.scss";

section#CostOfOwnership {
    .comparison-text {
        font-size: 0.75rem;
    }
}

.cost-comparison, .ComparedCarDetails {
    position: relative;
    .show-full-list-container {
        button {
            position: absolute;
            right: 0;
            bottom: 0;

            border-color: $darkBlue;
            border-width: 1px;
            border-style: solid;
            border-radius: 8px;
            padding: 0.5rem 1.5rem;
            background: none;
            background-color: white;
            color: $darkBlue;
            font-weight: 900;
        }
    }
}