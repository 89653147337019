@import "src/client_customizations/scss/client_master.scss";

.hcFilterControls {
    margin-bottom: 1rem;

    .socket-text {
        float: left;
    }
    .socket-image {
        float: right;
    }
}