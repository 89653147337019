@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	background-color: white;
	margin-right: 2%;
	margin-bottom: 1%;
	border-radius: 0;
	position: relative;

	.incentive-type {
		margin-top: -1rem;
		text-transform: uppercase;
		font-family: $font_family_2;
		font-size: 1.25rem;
		font-weight: 600;
	}
	.card-title {
		font-size: 2.5rem;
		height: 5.5rem;
	}
	.incentive-card-body {
		height: 15rem;
		.card-name-container {
			padding-top: 1rem;
		}
	}
	.IncentiveCardBottom {
		padding-top: 2.5rem;
		p {
			font-size: 0.75rem;
			height: 10rem;
			overflow: hidden;	
			text-overflow: ellipsis;
		}
		p:hover {
			overflow: visible;
			min-height: 10rem;
			height: auto;
		}
	}
}

.HomeIncentiveCardBody, .IncentiveCardBody {
	padding: 1.5rem;
	background-color: white;
	text-align: center;
	border-style: solid;
	border-width: 9px 0px 0px 0px;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	min-height: 8rem;
	.card-title {
		margin-top: 1rem;
		color: $IncentiveCard-card-title-color;
		height: auto;
		margin-bottom: 1rem;
	}
	.dollar-sign-icon {
		width: 45%;
	}
}
.IncentiveCardBody {
	border-color:$indigo;
}
.HomeIncentiveCardBody {
	border-color:$indigo;
}
.BWPIncentinveCardBody {
	border-color:$blue;
}
.IncentiveCardBottom {
	margin-top: -2rem;
	background-color: white;
	padding: 1rem;
	text-align: left !important;
	max-height: 24rem;
	p {
		padding-top: 1rem;
	}
}
.IncentiveText {
	text-align: left;
	max-width: 800px;
	margin: 0 auto;
}
@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 32%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}
