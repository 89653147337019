@import "src/client_customizations/scss/client_master.scss"; 

.CalcPanels {
    .btn.active {
        background: linear-gradient(to right, $button_grad_start, $button_grad_end); 
        color: white;
    }
    .btn {
        background: none;
        color: #0072ce;
    }
}