@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	border-bottom: $Header-border-bottom;
	a {
		color:$Header-link-color;
	}
	a.nav-link {
		margin-top: -10px; /* TODO not sure why this is necessary... but I don't see where nav-link has style rules anywhere */
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 255px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}

      .navbar-nav {
		align-items: center;
	  }
	  
	  .dropdown-toggle {
		  margin-top: 0;
	  }

      .dropdown-item {
        border-radius: 0;
      }
	}

    .dropdown-menu {
	  top: 30px;
	  right: 20px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
	}
	.nav-item {
		margin: 5px;
		text-transform: uppercase;
		font-size: 16px;
		text-align: center;
	}
}
// @TODO where is this originally set?
.dropdown-item {
	text-transform: none;
	font-size: 12px;
	text-align: left;
	font-weight: 500 !important;
}
.dropdown-item.active {
	background-color: $offWhite;
	border-radius: 0px !important;

}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}
