@import "src/client_customizations/scss/client_master.scss"; 

.CompareVehicles {
		padding-top:40px;

		.compared-vehicle-container {
			padding-top: 20px;
		}

		.single-vehicle {
			padding-top: 50px;
			padding-bottom: 50px;
		}

		.electric-range-graph {
			padding-right: 0;

			button {
				position: absolute;
				right: 0;
				bottom: 0;
	
				border-color: $darkBlue;
				border-width: 1px;
				border-style: solid;
				border-radius: 8px;
				padding: 0.5rem 1.5rem;
				background: none;
				background-color: white;
				color: $darkBlue;
				font-weight: 900;
			}
	
		}
}