@import "src/client_customizations/scss/client_master.scss";

.EVsSort {
	margin-bottom: 15px;
	label {
		font-weight: $EVsSort-label-font-weight;
		font-size: $EVsSort-label-font-size;
		color: $EVsSort-label-color;
		letter-spacing:$EVsSort-label-letter-spacing;
		line-height: $EVsSort-label-line-height;
	}
	select {
		// background: $EVsSort-select-background;
		border: $EVsSort-select-border;
		// border-radius:$EVsSort-select-border-radius;
		font-size: $EVsSort-select-font-size;
		color: $EVsSort-select-color;
		letter-spacing: $EVsSort-select-letter-spacing;
	}
}

.ev-toggle {
	padding:0;
	display: flex;
	background-color:#fff;
	margin:50px 0 35px;
	box-shadow: 0 3px 7px -2px rgba(2,63,32,.3);

	a {
		width:50%;
		text-align: center;
		color:#2A2A2A;
		text-transform: uppercase;
		background-color:#fff;
		padding:20px 0;
		font-size:16px;
		font-weight: 600;
		border-radius: 5px;
		transition: all .2s ease-out;
		
		&.active {
			background: linear-gradient(to right, $button_grad_start, $button_grad_end); 
			color:#fff;
		}

		&:hover {
			text-decoration: none;
			// background-color:#E3001C;
			// color:#fff;
		}
	}
}

@media (min-width: 576px) {
	.EVsSort {
		float: right;
	}
}

.cost-vs-range-graph {
	.evs-toggle-image {
		padding-right: 8px;
	}
	.toggle-btn{
		padding: 10px 40px;
		font-size: 18px;
		border-radius: 0;
		border: 0;
		color: $darkBlue;
		font-weight: 500;
		background-color: #fff;
		border: 1px solid $darkBlue;
		vertical-align: middle;
		margin-left: 2px;
		margin-right:2px;
		.toggle-image-hover {
			display:none;
		}
		&:hover {
			border-radius: 0;
			color: #fff;
			font-weight: 500;
			text-decoration: none;
			background-color: $darkBlue;
			.toggle-image-passive {
				display:none;
			}
			.toggle-image-hover {
				display:inline;
			}
		}
	}
	
	.toggle-btn-active{
		padding: 10px 40px;
		font-size: 18px;
		border-radius: 0;
		border: 0;
		color: #fff;
		font-weight: 500;
		background-color: $darkBlue;
		border: 1px solid $darkBlue;
		vertical-align: middle;
		margin-right: 2px;
		margin-left: 2px;
		.toggle-image-passive {
			display:none
		}
	}
}